@import url('sanitize.css');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700;800&display=swap');
@import 'variables';
@import '@angular/material/prebuilt-themes/deeppurple-amber.css';
@import "loader";

html {
  overflow-y: hidden !important;
}

body {
  font-size: 0.875rem;
  background: #f3f3f3;
  font-family: Raleway, "Helvetica Neue", sans-serif !important;
}

button {
  cursor: pointer;
}

a, a:hover {
  text-decoration: none;
  outline: inherit;
}

span {
  white-space: nowrap;
}

.table {
  border: 1px solid #00000017;

  th,
  td {
    border-right: 1px solid #dee2e6;
  }
}

th, td {
  white-space: nowrap;
}

.card {
  border: unset !important;
}

.w-92 {
  width: 92% !important;
}

.w-85 {
  width: 85% !important;
}

.w-86 {
  width: 86% !important;
}

.ng2-tag-input {
  border: 1px solid #ced4da !important;
}

.full-card {
  overflow: auto;
}

.card-header-left {
  left: 0;
  top: 0;
  display: inline-block;
  float: left;
  position: absolute;
}

i {
  cursor: pointer;
}

.icofont-home {
  cursor: auto !important;
}

.input-group-prepend .input-group-text {
  background-color: #0f58d6 !important;
  border-radius: unset;
}

.btn-link {
  color: black !important;
  font-weight: bold !important;
  cursor: pointer;
}

.h-40 {
  height: 40px !important;
}

.control-error {
  border: 1px solid red;
}

.cursor-pointer {
  cursor: pointer !important;
}

button:focus,
button:active:focus,
button.active:focus {
  outline: none !important;
  outline-style: none !important;
}

.mat-checkbox-layout {
  margin-bottom: 0 !important;
}

.material-icons {
  cursor: pointer !important;
}

.m-b-0 {
  margin-bottom: 0 !important;
}

.m-b-1 {
  margin-bottom: 10px !important;
}

.modal-backdrop {
  z-index: 998 !important;
}

.modal {
  z-index: 999 !important;
}

.text-start {
  text-align: start;
}

.sortable-list {
  padding: 15px !important;
}

.sortable-container .sortable-list li {
  margin: 10px 0;

  &.active {
    background: none !important;
    color: inherit !important;
  }
}

@media only screen and (max-width: $normalw) {
  app-skf-text-field {
    margin-bottom: 10px !important;
  }
  app-skf-text-area {
    .skf-text-area {
      margin-bottom: 10px !important;
    }
  }
}

@media only screen and (max-width: $headerW) {
  .cdk-overlay-pane {
    left: 26px !important;
    position: absolute !important;
  }
}

perfect-scrollbar {
  overflow-y: visible !important;
}

// styles for mat form field
@media only screen and (max-width: $tabletw) {
  @-moz-document url-prefix() {
    .mat-figure {
      .mat-form-field-type-mat-select {
        width: 12vw;
      }
    }
  }
}

.mat-figure {
  .mat-form-field-type-mat-select {
    width: -webkit-fill-available;
  }
}

.mat-form-field-type-mat-select {
  .mat-form-field-flex {
    background: white !important;
    border-radius: 10px !important;
  }
}

// mat-form-field {
//   ::ng-deep .mat-form-field-flex {
//     padding: 0px 10px 2px 10px !important;
//   }
// }

// .mat-form-field-appearance-fill .mat-form-field-flex{
//   padding: 0px 10px 2px 10px !important;
// }

.mat-form-field-underline {
  display: none;
}

.mat-form-field-wrapper {
  padding-bottom: 0 !important;
}

.mat-select-panel {
  max-width: 100% !important;
}

.product-name{
  right: 50px !important;
}

.sub-category-label {
  display: flex;
  margin: 0;
}