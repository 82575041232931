@keyframes ball-scale {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}


@-webkit-keyframes ball-scale {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}


.ball-scale {
  left: 50%;
  top: 50%;
  position: absolute;
  height: 100px;
  width: 100px;
  margin: -50px 0 0 -50px;

  > div {
    -webkit-animation: ball-scale 2s linear infinite;
    animation: ball-scale 2s linear infinite;
    border: 5px solid #0f58d6;
    border-top-color: transparent;
    border-radius: 50%;
    height: 100%;
    width: 100%;
    position: relative;

    &:before, &:after {
      -webkit-animation: ball-scale 1s linear infinite;
      animation: ball-scale 1s linear infinite;
      content: "";
      position: absolute;
      border: 5px solid #0f58d6;
      border-top-color: transparent;
      border-radius: 50%;
    }

    &:before {
      height: 80%;
      width: 80%;
      top: 10%;
      left: 10%;
    }

    &:after {
      -webkit-animation: ball-scale 0.5s linear infinite;
      animation: ball-scale 0.5s linear infinite;
      height: 50%;
      width: 50%;
      top: 25%;
      left: 25%;
    }
  }
}
